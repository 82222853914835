import React, { useCallback, useEffect, useState } from "react";
import axios, { AxiosError } from 'axios'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

type NewProductDTO = {
    name: string
}

type ProductDTO = {
    id: number;
    name: string;
}

const ProductComponent: React.FC = () => {
    const { instance, accounts } = useMsal();
    const [products, setProducts] = useState<ProductDTO[]>([]);
    const [productName, setProductName] = useState<string>("");

    const fetchProducts = useCallback(() => {
        instance
            .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
            .then((tokenResponse) => {
                axios.get("/products", { headers: { "Authorization": `Bearer ${tokenResponse.idToken}` } })
                    .then(response => {
                        setProducts(response.data);
                    })
                    .catch((e) => {
                        const error = e as AxiosError;
                        alert("Error fetching products: " + JSON.stringify(error.response?.data));
                    });
            })
    }, [instance, accounts])

    const handleAddProduct = () => {
        if (!productName || productName.length > 255) {
            alert("Invalid Product name.")
            return
        }

        const product: NewProductDTO = {
            name: productName
        };

        instance
            .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
            .then((tokenResponse) => {
                axios.post("/products", product, { headers: { "Authorization": `Bearer ${tokenResponse.idToken}` } })
                    .then(() => {
                        fetchProducts();
                        setProductName("");
                    })
                    .catch((e) => {
                        const error = e as AxiosError;
                        alert("Error adding product: " + JSON.stringify(error.response?.data));
                    });
            });
    }

    const handleDeleteProduct = (id: number) => {
        instance
            .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
            .then((tokenResponse) => {
                axios.delete(`/products/${id}`, { headers: { "Authorization": `Bearer ${tokenResponse.idToken}` } })
                    .then(() => {
                        fetchProducts();
                    })
                    .catch((e) => {
                        const error = e as AxiosError;
                        alert("Error deleting product: " + JSON.stringify(error.response?.data));
                    });
            });
    }

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    return (
        <div>
            <h2>Product Management</h2>

            <div>
                <input
                    type="text"
                    placeholder="Product Name"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                />
                <button onClick={handleAddProduct}>Add Product</button>
            </div>

            <div>
                {products.map(product => (
                    <div key={product.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                        <p>{product.name}</p>
                        <button onClick={() => handleDeleteProduct(product.id)}>
                            Delete Product
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductComponent;
