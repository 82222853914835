import { InteractionStatus } from "@azure/msal-browser";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react"
import React, { useEffect, useState } from "react";
import { loginRequest } from "../authConfig";

const Authentication: React.FC = () => {
    const { instance, inProgress, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");

    useEffect(() => {
        if (account && account.name) {
            setName(account.name);
            setUsername(account.username);
        } else {
            setName("");
            setUsername("");
        }
    }, [account]);

    let content = <h2>Authentication</h2>
    if (isAuthenticated) {
        content = <>
            {content}
            <button onClick={() => instance.logoutRedirect()}>Sign out</button>
            <p>Currently logged as {name} ({username})</p>
        </>
    } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
        content = <>
            {content}
            <button onClick={() => instance.loginRedirect(loginRequest)}>Sign in</button>
        </>
    }

    return content
}

export default Authentication;
