import { IPublicClientApplication } from '@azure/msal-browser';
import './App.css';
import Config from "./config.json"
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import Authentication from "./components/Authentication"
import Product from "./components/Product"

type AppProps = {
  pca: IPublicClientApplication
}

function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <div className="App">
        <h1>CCC example internet application ({Config.ENV})</h1>
        <Authentication />

        <AuthenticatedTemplate>
          <Product />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <p><i>Sign in to view product management.</i></p>
        </UnauthenticatedTemplate>
      </div>
    </MsalProvider>
  );
}

export default App;
