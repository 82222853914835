import { Configuration } from "@azure/msal-browser"
import Config from "./config.json"



export const msalConfig: Configuration = {
    auth: {
        clientId: Config.MSAL_CLIENT_ID,
        authority: Config.MSAL_AUTHORITY,
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
}

export const loginRequest = {
    scopes: ['openid', 'profile', 'offline_access']
}